import React,{useState} from "react"
import PropTypes from "prop-types"
import {Link} from 'gatsby'
import Image from 'gatsby-image'
import {
  FaFacebookF,
  FaTwitter,
  FaGooglePlusG,
  FaLinkedinIn,
} from "react-icons/fa"
import { store } from 'react-notifications-component';
import BlogFeedback from './BlogFeedback'

const BlogDetails = ({blog}) => {
  const {name,blogby, image, tags, description}= blog
  const [formData, setFormData] = useState(null);

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }
  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }
  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...formData,
      }),
    })
      .then(() => {
        setFormData(null)
        store.addNotification({
          title: "Feedback",
          message: "Data added successfully",
          type: "success",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        })
      })
      .catch(error => console.log("error>>>", error));
  }
  return (
    <div>
      <div className="BlogDetails">
        <h4 className="BlogTitle">
          {name}
        </h4>
        <div className="BlogCard">
          <div className="BlogImg">
            <Image fluid={image.fluid} alt={name} />
          </div>
          <div className="BlogInfo">
            <h6 className="BlogBy">{blogby}</h6>
            <div className="tagsRow">
              {tags.map(tagData=>(
                <Link to={tagData.link} key={tagData.name} className="tag">{tagData.name}</Link>
              ))}
            </div>
            <div className="BlogDesc" dangerouslySetInnerHTML={{ __html: description.description }}></div>
            
            <div className="shareRow">
              <h5>Share this Blog now</h5>
              <div className="ShareOn">
                <a href="#" className="fb">
                  <span className="fbIC">
                    <FaFacebookF></FaFacebookF>
                  </span>
                  Share on Facebook
                </a>
                <a href="#" className="Twitter">
                  <span className="TwIC">
                    <FaTwitter></FaTwitter>
                  </span>
                  Share on Twiter
                </a>
                <a href="#" className="GoogleG">
                  <FaGooglePlusG></FaGooglePlusG>
                </a>
                <a href="#" className="linkedIn">
                  <FaLinkedinIn></FaLinkedinIn>
                </a>
              </div>
            </div>

            <BlogFeedback handleChange={handleChange} handleSubmit={handleSubmit}formData={formData}/>

            {/* <div className="response">
              <h5 className="title">Response</h5>
              <div className="response_list">
                <div className="responseImg">
                  <img src={responseImg} />
                </div>
                <div className="responseContent">
                  <h5 className="ResponseBy">Priyesh Singhai</h5>
                  <small>21 Feb 2018 </small>
                  <p>
                    Ut error perpetua has, ut graeco fastidii patrioque pri.
                    Iusto tractatos iracundia sea te. Ut error perpetua has, ut
                    graeco fastidii patrioque pri. Iusto tractatos iracundia sea
                    te.
                  </p>
                </div>
              </div>
              <div className="response_list">
                <div className="responseImg">
                  <img src={responseImg} />
                </div>
                <div className="responseContent">
                  <h5 className="ResponseBy">Priyesh Singhai</h5>
                  <small>21 Feb 2018 </small>
                  <p>
                    Ut error perpetua has, ut graeco fastidii patrioque pri.
                    Iusto tractatos iracundia sea te. Ut error perpetua has, ut
                    graeco fastidii patrioque pri. Iusto tractatos iracundia sea
                    te.
                  </p>
                </div>
              </div>
              <div className="response_list">
                <div className="responseImg">
                  <img src={responseImg} />
                </div>
                <div className="responseContent">
                  <h5 className="ResponseBy">Priyesh Singhai</h5>
                  <small>21 Feb 2018 </small>
                  <p>
                    Ut error perpetua has, ut graeco fastidii patrioque pri.
                    Iusto tractatos iracundia sea te. Ut error perpetua has, ut
                    graeco fastidii patrioque pri. Iusto tractatos iracundia sea
                    te.
                  </p>
                </div>
              </div>
              <div className="response_list">
                <div className="responseImg">
                  <img src={responseImg} />
                </div>
                <div className="responseContent">
                  <h5 className="ResponseBy">Priyesh Singhai</h5>
                  <small>21 Feb 2018 </small>
                  <p>
                    Ut error perpetua has, ut graeco fastidii patrioque pri.
                    Iusto tractatos iracundia sea te. Ut error perpetua has, ut
                    graeco fastidii patrioque pri. Iusto tractatos iracundia sea
                    te.
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

BlogDetails.propTypes = {
  blog:PropTypes.object
}

export default BlogDetails
