import React from "react"
import {
  MdSearch,
} from "react-icons/md"

const BlogSideBox = () => {
    return(
        <div>
        <div className="SearchBox sideBar">
                <h4 className="SideBarLable">Search Box</h4>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Click here to search"
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <a href="#">
                        <MdSearch />
                      </a>
                    </span>
                  </div>
                </div>
              </div>
              <div className="Categories sideBar">
                <h4 className="SideBarLable">Categories</h4>
                <ul>
                  <li>
                    <a href="#">Technology</a>
                  </li>
                  <li>
                    <a href="#">Business</a>
                  </li>
                  <li>
                    <a href="#">Database</a>
                  </li>
                  <li>
                    <a href="#">Students</a>
                  </li>
                  <li>
                    <a href="#">Professionals</a>
                  </li>
                  <li>
                    <a href="#">Other</a>
                  </li>
                </ul>
              </div>
              </div>
    )
}

export default BlogSideBox