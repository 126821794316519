import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogSideBox from "../components/Blog/BlogSideBox"
import BlogDetails from "../components/Blog/BlogDetails"
import Image from "gatsby-image"
import { Link } from "gatsby"

const CourseTemplate = ({ data }) => {
  const replaceHtmlContent = () => {
    let shortDescription = data.blog.description.description.substr(
      0,
      data.blog.description.description.indexOf(".")
    )
    shortDescription = shortDescription.toString()
    shortDescription = shortDescription.replace(/<[^>]*>/g, "")
    return shortDescription
  }
  return (
    <Layout>
      <SEO title={"blog"} />
      <div className="BlogDetailsPage">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <BlogDetails blog={data.blog} />
            </div>
            <div className="col-md-3">
              <BlogSideBox />
            </div>
          </div>
          <div className="ReadAlso">
            <h5 className="title">Read Also</h5>
            <div className="row">
              {data.blog.tagedblog.map(tagedblog => (
                <div className="col-md-4" key={tagedblog.id}>
                  <div className="blogCard">
                    <div className="blogIMG">
                      <Image
                        fluid={tagedblog.image.fluid}
                        className="img-fluid"
                        alt={tagedblog.name}
                      />
                    </div>
                    <div className="BlogInfo">
                      <h6 className="BlogBy">{tagedblog.blogby}</h6>
                      <h4 className="BlogTitle">{tagedblog.name}</h4>
                      <p className="BlogDesc">{replaceHtmlContent()}</p>
                      <div className="tagsRow">
                        {tagedblog.tags.map(tagData => (
                          <Link
                            to={tagData.link}
                            key={tagData.name}
                            className="tag"
                          >
                            {tagData.name}
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const blogsBySlugQuery = graphql`
  query($slug: String!) {
    blog: contentfulBrainyFiedBlog(slug: { eq: $slug }) {
      id
      name
      blogby
      image {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      tags {
        link
        name
      }
      description {
        description
      }
      tagedblog {
        id
        name
        blogby
        image {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        tags {
          link
          name
        }
        description {
          description
        }
      }
    }
  }
`

export default CourseTemplate
