import React from "react"

const BlogFeebback = ({ handleSubmit, handleChange,formData }) => {
    return(
        <div className="Feedback">
              <h5>Share your feedback on this</h5>
              <form
              name="blogFeedback"
              method="POST"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
              >
                <input type="hidden" name="form-name" value="contact" />
                <div className="row">
                  <div className="form-group col-md-6">
                    <input
                      type="text"
                      name="fullName"
                      className="form-control"
                      placeholder="Full Name"
                      onChange={handleChange}
                      value={formData?formData.fullName:''}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Email"
                      onChange={handleChange}
                      value={formData?formData.email:''}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    name="response"
                    rows="5"
                    placeholder="Write a Response"
                    onChange={handleChange}
                    value={formData?formData.response:''}
                  />
                </div>

                <div className="text-right">
                  <button type="submit" className="btn btn-theme btn-success">
                    Submit
                  </button>
                </div>
              </form>
            </div>
    )
}
export default BlogFeebback